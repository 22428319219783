<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['proneToAcne'])">
      <single-answer-question
        title="label.proneToAcne"
        :value="proneToAcne"
        :options="proneToAcneOptions"
        dysfunction="pimples"
        yes-no
        @input="onFieldChange('proneToAcne', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      v-if="isFacialAcneCoverageQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="acneDetailQuestionsVisible"
        :error="getFieldsError(['facialAcneCoverage'])"
        class="q-mt24 q-mt32-md"
      >
        <single-picture-select
          id="facial-acne-coverage"
          title="label.facialAcneCoverage"
          :gender="gender"
          :value="facialAcneCoverage"
          :options="acneCoverageOptions"
          @input="onFieldChange('facialAcneCoverage', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <question-divider
      v-if="isAcneDiscomfortFrequencyQuestionDividerVisible"
      class="q-mt8 q-mb32 q-mt16-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="acneDetailQuestionsVisible"
        :error="getFieldsError(['acneDiscomfortFrequency'])"
      >
        <single-answer-question
          id="acne-discomfort-frequency"
          title="label.pimpleDiscomfortFrequency"
          :value="acneDiscomfortFrequency"
          :options="$options.discomfortOptions"
          dysfunction="pimples"
          @input="onFieldChange('acneDiscomfortFrequency', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="acneDetailQuestionsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['firstAcneOccurrence'])"
      >
        <single-answer-question
          id="first-acne-occurrence"
          title="label.firstPimpleOccurrence"
          :value="firstAcneOccurrence"
          :options="$options.occurenceOptions"
          dysfunction="pimples"
          @input="onFieldChange('firstAcneOccurrence', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SinglePictureSelect from '@/modules/questionnaire/new-design-components/questions/SinglePictureSelect';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { ACNE_FREQUENCY, ACNE_DURATION } from '@/modules/questionnaire/api/constants';
import { COVERAGE_OPTIONS } from '@/modules/questionnaire/constants/steps/pimples';

const DISCOMFORT_OPTIONS = [
  { value: ACNE_FREQUENCY.UNDER_A_WEEK, text: 'pimpleDiscomfortFrequency.underAWeek' },
  {
    value: ACNE_FREQUENCY.TWO_TO_THREE_WEEKS,
    text: 'pimpleDiscomfortFrequency.moreThanTwoWeeksAMonth'
  }
];

const OCCURENCE_OPTIONS = [
  { value: ACNE_DURATION.UNDER_A_YEAR, text: 'firstPimpleOccurrence.underAYear' },
  { value: ACNE_DURATION.ONE_TO_THREE_YEARS, text: 'firstPimpleOccurrence.moreThanOneYear' }
];

export default {
  name: 'PimplesTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    QuestionWithErrorWrap,
    SingleAnswerQuestion,
    SinglePictureSelect
  },
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  discomfortOptions: DISCOMFORT_OPTIONS,
  occurenceOptions: OCCURENCE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    gender: {
      type: String,
      default: ''
    },
    proneToAcne: {
      type: String,
      default: ''
    },
    proneToAcneAiSelected: {
      type: String,
      default: ''
    },
    facialAcneCoverage: {
      type: String,
      default: ''
    },
    facialAcneCoverageAiSelected: {
      type: String,
      default: ''
    },
    acneDiscomfortFrequency: {
      type: String,
      default: ''
    },
    firstAcneOccurrence: {
      type: String,
      default: ''
    },
    acneDetailQuestionsVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    proneToAcneOptions() {
      return flagAiSelectedOption(YES_NO_RESPONSE_OPTIONS, this.proneToAcneAiSelected);
    },
    acneCoverageOptions() {
      return flagAiSelectedOption(COVERAGE_OPTIONS, this.facialAcneCoverageAiSelected);
    },
    isFacialAcneCoverageQuestionDividerVisible() {
      return this.acneDetailQuestionsVisible && !this.facialAcneCoverageAiSelected;
    },
    isAcneDiscomfortFrequencyQuestionDividerVisible() {
      return this.acneDetailQuestionsVisible && !this.isFacialAcneCoverageQuestionDividerVisible;
    }
  }
};
</script>
