import { COVERAGE, ACNE_TYPE } from '@/modules/questionnaire/api/constants';

export const COVERAGE_OPTIONS = [
  { value: COVERAGE.SMALL },
  { value: COVERAGE.MEDIUM },
  { value: COVERAGE.LARGE }
];

export const ACNE_TYPE_OPTIONS = [
  { value: ACNE_TYPE.PAPULES },
  { value: ACNE_TYPE.PUSTULES },
  { value: ACNE_TYPE.NODULES }
];
